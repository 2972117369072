import "slick-carousel/slick/slick";

$(".hero-slides").slick({
  autoplay: false,
  arrows: false,
  dots: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplaySpeed: 1500,
  speed: 500,
});